import { Calendar, Location, People, Tree, CloseCircle } from 'iconsax-react';
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';

export default function CampDiscountModal() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const initialTargetDate = new Date('2025-03-09T10:00:00').getTime();
  const nextTargetDate = new Date('2025-04-26T10:00:00').getTime(); // Yeni tarih

  const [campDates, setCampDates] = useState('26-27 Nisan');

  useEffect(() => {
    const now = new Date().getTime();

    if (now >= initialTargetDate && now < nextTargetDate) {
      setCampDates('26-27 Nisan');
    }
  }, []);

  // useEffect(() => {
  //   const { pathname, search } = location;
  //   const data: any = JSON.parse(
  //     localStorage.getItem('isModalShown') as string
  //   );
  //   if (data) {
  //     const now = new Date();
  //     const storedDate = new Date(data.timestamp);

  //     const differenceInDays =
  //       (now.getTime() - storedDate.getTime()) / (1000 * 60 * 60 * 6);

  //     if (differenceInDays > 1) {
  //       localStorage.removeItem('isShown'); //6 saat geçtiyse localStorage temizle
  //       setOpen(true);
  //     } else {
  //       setOpen(false);
  //     }
  //   } else {
  //     if (
  //       pathname === '/marketplace-orders' ||
  //       pathname.startsWith('/marketplace/products/') ||
  //       (pathname === '/amazon-forecast' && search.includes('asin='))
  //     ) {
  //       setOpen(false);
  //     } else {
  //       setOpen(true);
  //     }
  //   }

  // }, []);
  useEffect(() => {
    const { pathname, search } = location;

    // Modalın açılmasını istemediğiniz sayfalar
    const blockedPaths = [
      '/marketplace-orders',
      '/marketplace-supplier-orders',
    ];

    // Modalın açılmasını engelleyecek koşulları kontrol ediyoruz
    const isBlockedPage =
      blockedPaths.includes(pathname) ||
      pathname.startsWith('/marketplace/products/');

    if (isBlockedPage) {
      setOpen(false); // Eğer bu sayfalardaysa modal açılmasın
      return; // İşlemi burada durduruyoruz çünkü modal açılmayacak
    }

    // localStorage'da data kontrolü yapılıyor
    const storedData = localStorage.getItem('isModalShown');

    if (storedData) {
      try {
        const data = JSON.parse(storedData); // JSON.parse hataya açık olduğundan try-catch bloğu ekledik

        const now = new Date();
        const storedDate = new Date(data.timestamp);
        const differenceInHours =
          (now.getTime() - storedDate.getTime()) / (1000 * 60 * 60);

        if (differenceInHours > 6) {
          localStorage.removeItem('isModalShown'); // 6 saat geçtiyse localStorage temizle
          setOpen(true); // Modalı aç
        } else {
          setOpen(false); // Zaman dolmamış, modalı açma
        }
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
        setOpen(true); // Bir hata varsa modalı aç
      }
    } else {
      // Eğer localStorage'da veri yoksa modalı aç
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    // Eğer URL '/marketplace-orders' değilse modal kapanabilir
    if (location.pathname !== '/marketplace-orders') {
      setOpen(false);
      localStorage.setItem(
        'isModalShown',
        JSON.stringify({ value: true, timestamp: new Date().toISOString() })
      );
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden bg-white rounded-[24px] p-[60px] text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg mt-16 min-h-[500px] lg:min-w-[528px] flex flex-col items-center justify-center gap-4">
                  <div className="flex relative sm:gap- items-center justify-center text-[16px]">
                    <Tree
                      variant="Bold"
                      color="#F97316"
                      className="w-12 h-12 sm:w-6 sm:h-6"
                    />
                    <p className="text-[#374151] text-center">
                      Yeni Kurduğumuz Tedarik Sistemini Duyurmak İçin
                    </p>
                  </div>
                  <div className="flex items-center justify-center sm:text-[32px] text-center font-semibold">
                    <p className="text-[#374151]">
                      2 Günlük Yüz Yüze <br />
                      <span className="relative inline-block z-10">
                        Amazon ve Etsy
                        <span className="absolute bottom-0 left-0 w-full h-2/5 bg-[#F97316] -z-10"></span>
                      </span>
                      <br />
                      Eğitimi Planladık
                    </p>
                  </div>
                  <p className="sm:text-[24px] text-[#374151] font-light text-center">
                    Yeni sistemde seçtiğiniz ürünler satıldıkça Rexven üretip
                    sizin adınıza kargolayacak
                  </p>
                  <Link
                    type="button"
                    className="rounded-[12px]  bg-[#1F254B] text-white py-3 px-6 sm:px-12 font-bold text-sm"
                    to={
                      'https://api.whatsapp.com/send?phone=905352051963&text=Merhaba%20Yüz%20yüze%20Amazon%20ve%20Etsy%20kampı%20hakkında%20bilgi%20almak%20istiyorum'
                    }
                    target="_blank"
                  >
                    Katılmak İçin Bilgi Al
                  </Link>
                  <div className="w-full flex flex-col items-start justify-center gap-2">
                    <div className="flex gap-1 items-center justify-center text-[16px]">
                      <Calendar
                        variant="Bold"
                        color="#F97316"
                        className="w-6 h-6"
                      />
                      <p className="text-[#374151]">
                        <span className="font-bold"> Tarih:</span>
                        {` ${campDates}`}
                      </p>
                    </div>
                    <div className="flex gap-1 items-start sm:items-center justify-center text-[16px]">
                      <Location
                        variant="Bold"
                        color="#F97316"
                        className="w-6 h-6"
                      />
                      <p className="text-[#374151]">
                        <span className="font-bold"> Yer:</span>
                        {` İstanbul`}
                      </p>
                    </div>
                    <div className="flex gap-1 items-center justify-center text-[16px]">
                      <People
                        variant="Bold"
                        color="#F97316"
                        className="w-6 h-6"
                      />
                      <p className="text-[#374151]">
                        <span className="font-bold"> Kontenjan:</span>
                        {` 30 Kişi`}
                      </p>
                    </div>
                  </div>
                  <div className="absolute right-0 top-0 pr-4 pt-4 ">
                    <button
                      type="button"
                      className="text-footerBackground "
                      onClick={handleClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {/* <div className=" absolute -right-6 -bottom-8 w-[400px] h-[400px] -z-10">
                                        <img
                                            src='/images/camp.webp'
                                            alt="Presentation"
                                            className="w-full h-full object-cover mask-image opacity-60"
                                            style={{
                                                WebkitMaskImage: `url(/images/campBg.webp)`,
                                                maskImage: `url(/images/campBg.webp)`,
                                                WebkitMaskSize: '100% 100%',
                                                maskSize: '100% 100%',
                                            }}
                                        />
                                    </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
