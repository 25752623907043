import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  allShipmentCost: any[];
}
const titles = {
  usps_ground_advantage: '(USPS 2-5 Gün)',
  usps_priority_mail: '(USPS 1-2 Gün)',
  ups_next_day_air: '(USPS 1 Gün',
  usps_first_class_mail_international: '(USPS 3-7 Gün)',
  ups_ground: '(UPS 1-5 Gün)',
};
const DemoShipmentGroup: React.FC<Props> = ({ allShipmentCost }) => {
  return (
    <fieldset className="w-32">
      <div className="space-y-2">
        {allShipmentCost.map((plan) => (
          <div key={uuidv4()} className="relative flex items-center">
            <div className="flex h-6 items-center">
              <input
                disabled={true}
                name={`shipment`}
                defaultChecked={plan.serviceCode === 'shipstation'}
                value={plan.serviceCode}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-4 text-xs leading-8 w-36">
              <p className=" text-gray-900">
                ${plan.shipmentCost.toFixed(2)}
                {` ${titles[plan.serviceCode as keyof typeof titles]}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default DemoShipmentGroup;
